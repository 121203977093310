import React from "react"
import styled from "styled-components"
import WorkCard from "../../reusable/WorkCard"
import { FlexContainer } from "../../../styles/layout.styled"
import { P } from "../../../styles/shared.styled"
import { WORK } from "../../../constants"
import { useInView } from "react-intersection-observer"

const SectionThreeContainer = styled(FlexContainer)`
  padding: 52px 14px 0;
  flex-direction: column;
  align-items: center;
  background: transparent;
`

const WorkCardsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0)" : "translateX(1400px)"};
  transition: all 0.9s ease;
`

const SectionThree = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true,
  })
  return (
    <SectionThreeContainer ref={ref}>
      <StyledP inView={inView} margin="0 0 20px" color="white">
        OUR WORK
      </StyledP>
      <StyledP inView={inView} fontSize="24px" fontWeight="bold" color="white">
        FEATURED WORK
      </StyledP>
      <WorkCardsContainer margin="45px 0 0">
        {WORK.map(({ id, title, category, url }, index) => (
          <WorkCard
            key={id}
            title={title}
            category={category}
            url={url}
            index={index}
          />
        ))}
      </WorkCardsContainer>
    </SectionThreeContainer>
  )
}

export default SectionThree
