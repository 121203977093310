import React, { useRef } from "react"
import LeftArrow from "../../../assets/svg/LeftArrow.svg"
import RightArrow from "../../../assets/svg/rightArrow.svg"
import ClientCard from "./ClientCard"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper"
import { graphql, useStaticQuery } from "gatsby"
import useRefs from "../../../hooks/useRefs"
import useMaximumElementsHeight from "../../../hooks/useMaximumElementsHeight"

const SwiperContainer = styled(FlexContainer)`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 72px 2em 0;
  align-items: center;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    padding: 72px 20px;
  }
`

const LeftArrowContainer = styled.div`
  margin-right: 30px;
  cursor: pointer;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    display: none;
  }
`
const RightArrowContainer = styled.div`
  margin-left: 30px;
  cursor: pointer;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    display: none;
  }
`

const customerReviewQuery = graphql`
  {
    allContentfulCustomerReviews(sort: { fields: createdAt, order: DESC }) {
      nodes {
        customerName
        companyName
        customerReview
        rate
        id
        customerPicture {
          file {
            url
          }
        }
      }
    }
  }
`

const ClientCarousel = () => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const {
    allContentfulCustomerReviews: { nodes },
  } = useStaticQuery(customerReviewQuery)
  const elRefs = useRefs(nodes.length)
  const maximumElementHeight = useMaximumElementsHeight(elRefs)

  return (
    <SwiperContainer>
      <LeftArrowContainer ref={navigationPrevRef}>
        <LeftArrow />
      </LeftArrowContainer>
      <Swiper
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        breakpoints={{
          100: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {nodes.map((node, index) => (
          <SwiperSlide key={`${node?.id}-${index}`} style={{ cursor: "grab" }}>
            <ClientCard
              maximumElementHeight={maximumElementHeight}
              ref={elRefs[index]}
              customerName={node?.customerName}
              review={node?.customerReview}
              url={node?.customerPicture.file.url}
              company={node?.companyName}
              rate={node?.rate}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <RightArrowContainer ref={navigationNextRef}>
        <RightArrow />
      </RightArrowContainer>
    </SwiperContainer>
  )
}

export default ClientCarousel
